@import url("https://fonts.googleapis.com/css?family=Quicksand:300,400,500,700|Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i");

// Colors

$light: rgb(255, 250, 250);
$medium: rgb(235, 235, 235);
$dark: rgb(12, 12, 12);
$highlight: rgb(93, 189, 202);

// Fonts
$headersfont: "Quicksand", sans-serif;
$bodyfont: "Roboto", sans-serif;
