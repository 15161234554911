@import "../../base-styles/variables.scss";

nav {
  height: 65px;
  width: 100%;
  background-color: $light;
  position: fixed;
  top: 0;
  display: flex;
  border-bottom: 2px solid $highlight;

  #name {
    font-family: $headersfont;
    font-weight: 300;
    font-size: 60px;
    text-transform: uppercase;
    margin: 2px 10px 2px 5px;
    display: block;
    min-width: 325px;
  }

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  a,
  li {
    display: inline-block;
    text-decoration: none;
    margin: 0px 5px;
  }

  #nav {
    line-height: 60px;
    font-size: 20px;
  }
}

// Tablet view
@media screen and (max-width: 875px) {
  nav {
    height: 120px;
    flex-direction: column;

    #name {
      text-align: center;
    }

    #nav {
      display: flex;
      align-content: left;
    }
  }
}
