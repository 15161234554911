@import "../../base-styles/variables.scss";

footer {
  height: 60px;
  width: 100%;
  font-family: $headersfont;

  p {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  a {
    display: block;
    text-align: center;
    text-decoration: none;
  }

  a:hover {
    font-weight: 400;
  }
}
