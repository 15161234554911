@import "../../base-styles/variables.scss";

#about {
  background-color: $medium;
  padding: 105px 40px 40px 40px;
  display: grid;
  grid-template-columns: 1fr 50%;
  border-bottom: 2px solid $highlight;

  img {
    max-width: 90%;
    min-width: 380px;
  }

  #about-content {
    margin: auto 0px;

    p {
      line-height: 25px;
      margin: 20px 5% 20px 0px;
    }
  }
}

// Tablet/mobile view
@media screen and (max-width: 875px) {
  #about {
    padding: 160px 10px 40px 10px;
    display: flex;
    flex-direction: column;

    img {
      margin: 0px auto;
      min-width: 325px;
    }

    #about-content {
      width: 90%;
      margin: 0px auto;

      h2 {
        margin-top: 30px;
        font-size: 35px;
      }

      p {
        line-height: 25px;
        margin: 20px auto 20px auto;
      }
    }
  }
}
