@import url(https://fonts.googleapis.com/css?family=Quicksand:300,400,500,700|Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Quicksand:300,400,500,700|Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Quicksand:300,400,500,700|Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Quicksand:300,400,500,700|Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Quicksand:300,400,500,700|Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Quicksand:300,400,500,700|Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i);
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

html {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 300;
  background-color: snow;
  min-width: 350px; }

h2 {
  font-family: "Quicksand", sans-serif;
  font-weight: 300;
  font-size: 40px;
  text-transform: uppercase; }

a {
  color: #0c0c0c; }

a:visited {
  color: #0c0c0c; }

a:hover {
  color: #5dbdca; }

nav {
  height: 65px;
  width: 100%;
  background-color: snow;
  position: fixed;
  top: 0;
  display: flex;
  border-bottom: 2px solid #5dbdca; }
  nav #name {
    font-family: "Quicksand", sans-serif;
    font-weight: 300;
    font-size: 60px;
    text-transform: uppercase;
    margin: 2px 10px 2px 5px;
    display: block;
    min-width: 325px; }
  nav div {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly; }
  nav a,
  nav li {
    display: inline-block;
    text-decoration: none;
    margin: 0px 5px; }
  nav #nav {
    line-height: 60px;
    font-size: 20px; }

@media screen and (max-width: 875px) {
  nav {
    height: 120px;
    flex-direction: column; }
    nav #name {
      text-align: center; }
    nav #nav {
      display: flex;
      align-content: left; } }

#about {
  background-color: #ebebeb;
  padding: 105px 40px 40px 40px;
  display: grid;
  grid-template-columns: 1fr 50%;
  border-bottom: 2px solid #5dbdca; }
  #about img {
    max-width: 90%;
    min-width: 380px; }
  #about #about-content {
    margin: auto 0px; }
    #about #about-content p {
      line-height: 25px;
      margin: 20px 5% 20px 0px; }

@media screen and (max-width: 875px) {
  #about {
    padding: 160px 10px 40px 10px;
    display: flex;
    flex-direction: column; }
    #about img {
      margin: 0px auto;
      min-width: 325px; }
    #about #about-content {
      width: 90%;
      margin: 0px auto; }
      #about #about-content h2 {
        margin-top: 30px;
        font-size: 35px; }
      #about #about-content p {
        line-height: 25px;
        margin: 20px auto 20px auto; } }

#contact {
  text-align: center;
  background-color: #ebebeb;
  height: 50%;
  border-bottom: 2px solid #5dbdca; }
  #contact img {
    width: 50px; }
  #contact p {
    margin: 0px 10px; }
  #contact img:hover {
    background-color: #5dbdca;
    border-radius: 25%; }
  #contact h2 {
    padding-top: 40px;
    margin-bottom: 20px; }
  #contact ul {
    width: 55%;
    margin: 20px auto;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    justify-items: center; }
    #contact ul li {
      margin-bottom: 40px; }

footer {
  height: 60px;
  width: 100%;
  font-family: "Quicksand", sans-serif; }
  footer p {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px; }
  footer a {
    display: block;
    text-align: center;
    text-decoration: none; }
  footer a:hover {
    font-weight: 400; }

#funWithCode {
  border-bottom: 2px solid #5dbdca;
  padding-bottom: 40px;
  width: 100%;
  margin: 0px auto; }
  #funWithCode h2 {
    text-align: center;
    padding-top: 70px;
    padding-bottom: 40px; }
  #funWithCode #projects {
    display: grid;
    grid-template-columns: auto auto;
    justify-items: center;
    align-items: center;
    grid-column-gap: 50px;
    -webkit-column-gap: 50px;
            column-gap: 50px;
    width: 80%;
    margin: 0px auto; }
    #funWithCode #projects .project {
      display: flex;
      flex-direction: column;
      margin: 20px 0px;
      width: 500px; }
      #funWithCode #projects .project img {
        width: 100%;
        height: 300px;
        object-fit: cover;
        border: 5px solid grey;
        margin-bottom: 10px; }
      #funWithCode #projects .project a:hover {
        font-style: italic;
        color: #5dbdca; }
        #funWithCode #projects .project a:hover img {
          border: 5px solid #5dbdca; }
  #funWithCode #more {
    width: 95%;
    display: flex;
    justify-content: center;
    margin: 20px 0px; }
    #funWithCode #more a {
      background-color: #5dbdca;
      padding: 20px;
      border-radius: 5px;
      text-decoration: none; }
      #funWithCode #more a:hover {
        color: snow; }

@media screen and (max-width: 1300px) and (min-width: 800px) {
  #funWithCode h2 {
    padding-top: 120px; }
  #funWithCode #projects {
    width: 80%;
    grid-column-gap: 25px;
    -webkit-column-gap: 25px;
            column-gap: 25px; }
    #funWithCode #projects .project {
      width: 100%; }
      #funWithCode #projects .project img {
        width: 100%;
        height: 300px; } }

@media screen and (max-width: 800px) {
  #funWithCode h2 {
    padding-top: 120px; }
  #funWithCode #projects {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%; }
    #funWithCode #projects .project {
      width: 100%; }
      #funWithCode #projects .project img {
        width: 100%;
        height: 300px; } }

