@import "./variables.scss";

@import url("https://fonts.googleapis.com/css?family=Quicksand:300,400,500,700|Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i");

* {
  box-sizing: border-box;
}

html {
  font-family: $bodyfont;
  font-size: 18px;
  font-weight: 300;
  background-color: $light;
  min-width: 350px;
}

h2 {
  font-family: $headersfont;
  font-weight: 300;
  font-size: 40px;
  text-transform: uppercase;
}

a {
  color: $dark;
}

a:visited {
  color: $dark;
}

a:hover {
  color: $highlight;
}
